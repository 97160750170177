<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          @change="update_site_list"
          :items="study_list"
          v-model="study_id"
          label="Study"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="update_assessment_list"
          :disabled="study_id == null"
          v-model="selected_site"
          :items="site_list"
          label="Site"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="main_run"
          :disabled="selected_site == null"
          :items="assessment_list"
          v-model="assessment_id"
          label="Assessment"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="main_run"
          :disabled="assessment_id == null"
          v-model="selected_subject"
          :items="subjects"
          label="Subject (optional)"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="main_run"
          :disabled="assessment_id == null"
          v-model="selected_visit"
          :items="visits"
          label="Visit (optional)"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col>
        <v-select></v-select>
      </v-col> -->
    </v-row>
    <v-row v-if="ready">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="filtered_data"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <!-- <vue-json-to-csv :json-data="jsonData">
                <v-btn color="secondary" class="mr-2">Export to CSV</v-btn>
              </vue-json-to-csv> -->
              <v-btn
                color="primary"
                :loading="pdf_loading"
                :disabled="pdf_loading"
                @click="export_to_pdf"
                >Export to PDF</v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td v-for="header in headers" class="text-center">
                <span v-if="!is_media(item[header.value])">
                  <span
                    v-if="
                      header.value == 'created_at' ||
                      header.value == 'updated_at' ||
                      header.value == 'submitted_at'
                    "
                  >
                    {{ date_fmt(item[header.value]) }}
                  </span>
                  <span v-else>
                    {{ item[header.value] }}
                  </span>
                </span>
                <span v-else>
                  <v-btn
                    @click="get_srs_tokens(item, item[header.value], item[header.value + '_media_data'])"
                    text
                    color="primary"
                  >
                    View Img
                    <span>
                      <v-icon
                        v-if="show_api_check(item, item[header.value], item[header.value + '_media_data']) == 'passed'"
                        color="green"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon
                        v-else-if="show_api_check(item, item[header.value], item[header.value + '_media_data']) == 'failed'"
                        color="red"
                      >
                        mdi-close
                      </v-icon>
                    </span>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Image </v-card-title>

        <v-card-text v-if="sas_token_paths && sas_token_paths.length > 0">
          <v-carousel v-if="sas_token_paths.length > 1">
            <v-carousel-item
              v-for="(sas_token_path, index) in sas_token_paths"
              :key="index"
              :src="sas_token_path.url"
            >
              <p
                class="text-center"
                style="color: white; background-color: rgba(0, 0, 0, 0.5)"
              >
                <a
                  style="color: white"
                  :href="sas_token_path.url"
                  target="_blank"
                >
                  Download Image </a
                ><br />
                <span>
                  {{ sas_token_path.api_response }}
                </span>
              </p>
            </v-carousel-item>
          </v-carousel>
          <div v-else>
            <v-img :src="sas_token_paths[0].url" />
            <p>
              <a :href="sas_token_paths[0].url" target="_blank">Download Image</a>
            </p>
            <p>{{ sas_token_paths[0].api_response }}</p>
          </div>
        </v-card-text>
        <v-card-text v-else-if="dialog_text == null">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mx-auto mt-3"
            :size="70"
            :width="7"
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else>
          <p>{{ dialog_text }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import VueJsonToCsv from "vue-json-to-csv";
import gql from "graphql-tag";
import axios from "axios";

//   dia_app_record(where: {study_id: {_eq: 24}, interval_assessment_id: {_eq: 40}, id: {_eq: 324}}) {
const MAIN_QUERY = gql`
  query GetAssessmentData(
    $assessment_id: Int!
    $study_id: Int!
    $user_id: Int!
    $site_id: Int!
  ) {
    dia_app_record(
      where: {
        study: {
          user_studies: { user_id: { _eq: $user_id } }
          user_study_sites: { user_id: { _eq: $user_id } }
        }
        study_id: { _eq: $study_id }
        site_id: { _eq: $site_id }
        interval_assessment: { assessment_id: { _eq: $assessment_id } }
      }
    ) {
      study {
        id
        name
        study_identifier
      }

      interval_assessment {
        id
        assessment {
          id
          name
          assessment_key
        }
        interval {
          id
          name
        }
      }
      site {
        id
        name
        identifier
      }
      subject {
        id
        subject_full_id
        subject_study_sites(
          where: { site_id: { _eq: $site_id }, study_id: { _eq: $study_id } }
        ) {
          id
          subject_full_id
        }
      }
      subject_study_site {
        id
        subject_full_id
      }
      id
      created_at
      updated_at
      submitted_at
      status
      archived
      out_of_assessment_window_completion
      out_of_interval_window_completion
      responses(order_by: { question: { sequence: asc } }) {
        value
        side
        media {
          id
          api_response_data
        }
        question {
          sequence
          label
          variable_name
        }
        option {
          label
          value
        }
      }
    }
  }
`;

const MAIN_QUERY_ADMIN = gql`
  query GetAssessmentData(
    $assessment_id: Int!
    $study_id: Int!
    $site_id: Int!
  ) {
    dia_app_record(
      where: {
        study: {
          user_studies: { user_id: {} }
          user_study_sites: { user_id: {} }
        }
        study_id: { _eq: $study_id }
        site_id: { _eq: $site_id }
        interval_assessment: { assessment_id: { _eq: $assessment_id } }
      }
    ) {
      study {
        id
        name
        study_identifier
      }
      interval_assessment {
        id
        assessment {
          id
          name
          assessment_key
        }
        interval {
          id
          name
        }
      }
      site {
        id
        name
        identifier
      }
      subject {
        id
        subject_full_id
        subject_study_sites(
          where: { site_id: { _eq: $site_id }, study_id: { _eq: $study_id } }
        ) {
          id
          subject_full_id
        }
      }
      subject_study_site {
        id
        subject_full_id
      }
      id
      created_at
      updated_at
      submitted_at
      status
      archived
      out_of_assessment_window_completion
      out_of_interval_window_completion
      responses(order_by: { question: { sequence: asc } }) {
        value
        side
        media {
          id
          api_response_data
        }
        question {
          id
          sequence
          label
          variable_name
        }
        option {
          id
          label
          value
        }
      }
    }
  }
`;

const STUDY_LIST = gql`
  query ListStudies($user_id: Int!) {
    dia_app_user_study(
      where: {
        archived: { _eq: false }
        user_id: { _eq: $user_id }
        user: {
          archived: { _eq: false }
          user_studies: { archived: { _eq: false } }
        }
        study: { archived: { _eq: false } }
      }
    ) {
      study {
        id
        name
      }
    }
  }
`;

const STUDY_LIST_ADMIN = gql`
  query ListStudies {
    dia_app_user_study {
      study {
        id
        name
      }
    }
  }
`;

const SITE_LIST = gql`
  query GET_SITE_LIST_BY_STUDY_ID($study_id: Int!, $user_id: Int!) {
    dia_app_site(
      where: {
        study_sites: { archived: { _eq: false } }
        user_study_sites: {
          user_id: { _eq: $user_id }
          study_id: { _eq: $study_id }
          study: {
            archived: { _eq: false }
            user_studies: {
              archived: { _eq: false }
              study: { archived: { _eq: false } }
            }
          }
          user: { archived: { _eq: false } }
          archived: { _eq: false }
        }
        archived: { _eq: false }
      }
    ) {
      id
      name
    }
  }
`;

const SITE_LIST_ADMIN = gql`
  query GET_SITE_LIST_BY_STUDY_ID($study_id: Int!) {
    dia_app_site(where: { study_sites: { study_id: { _eq: $study_id } } }) {
      id
      name
    }
  }
`;

const ASSESSMENT_LIST = gql`
  query GET_ASSESSMENT_LIST_BY_STUDY_ID($study_id: Int!, $user_id: Int!) {
    dia_app_interval_assessment(
      where: {
        study_id: { _eq: $study_id }
        study: { user_studies: { user: { id: { _eq: $user_id } } } }
      }
      distinct_on: assessment_id
    ) {
      assessment {
        id
        name
      }
    }
  }
`;

const ASSESSMENT_LIST_ADMIN = gql`
  query GET_ASSESSMENT_LIST_BY_STUDY_ID($study_id: Int!) {
    dia_app_interval_assessment(
      where: { study_id: { _eq: $study_id } }
      distinct_on: assessment_id
    ) {
      assessment {
        id
        name
      }
    }
  }
`;

export default {
  name: "AssessmentsView",
  mixins: [hasuraService],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      dialog_text: null,
      selected_site: null,
      selected_subject: "",
      selected_visit: null,
      sas_token_paths: null,
      selected_site: null,
      site_list: [],
      dialog: false,
      dialog_text: null,
      pdf_loading: false,
      ready: false,
      loading: true,
      headers: [],
      base_headers: [
        { text: "Record ID", value: "id" },
        { text: "Study Name", value: "study_name" },
        { text: "Study Identifier", value: "study_identifier" },
        { text: "Site Name", value: "site_name" },
        { text: "Site Identifier", value: "site_identifier" },
        { text: "Interval Name", value: "interval_name" },
        { text: "Assessment Name", value: "assessment_name" },
        { text: "Assessment Key", value: "assessment_key" },
        { text: "Subject", value: "subject_full_id" },
        { text: "Record Status", value: "record_status" },
        // { text: "Archived", value: 'archived' },
        // push records here
        {
          text: "Out of Assessment Window Completion",
          value: "out_of_assessment_window_completion",
        },
        // { text: "Out of Interval Window Completion", value: 'out_of_interval_window_completion' },
        { text: "Created At", value: "created_at" },
        { text: "Submitted At", value: "submitted_at" },
      ],
      assessment_id: null,
      study_id: null,
      study_list: [],
      assessment_list: [],
      data: [],
    };
  },
  methods: {
    show_api_check(item, item_h, media_data) {
      if (media_data == null) return "none";
      item_h = item_h.slice(7).split(", ").map((i) => parseInt(i));



      let result = media_data.map((m) => {
        if (item_h.includes(m.id)) {
          return m.api_response_data?.result; 
        } else {
          return null;
        }
      });

      if (result.includes(true)) {
        return "passed";
      }

      if (result.includes(false)) {
        return "failed";
      }

      return "none";
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    is_media(value) {
      if (value == null) return false;
      // if value is string
      if (typeof value === "string" || value instanceof String) {
        return value.slice(0, 5) == "MEDIA";
      }
      return false;
    },
    async is_image_available(url) {
      var req = new XMLHttpRequest();
      req.open("HEAD", url, true);
      req.send(null);
      try {
        return await new Promise((resolve, reject) => {
          req.onreadystatechange = function () {
            if (req.readyState == 2) {
              let x = req.getAllResponseHeaders();
              if (x.includes("image/jpeg")) {
                req.abort();
                resolve(true);
              } else {
                req.abort();
                resolve(false);
              }
            }
          };
        });
      } catch (error) {
        return false;
      }
    },
    async get_srs_tokens(item, image_paths_raw, full_media_data, _idx = 0) {
      let image_paths = image_paths_raw.slice(7);
      image_paths = image_paths.split(", ");
      this.sas_token_path = null;
      this.dialog = true;

      if (image_paths.length == 0) {
        this.dialog_text = "Image Not Found";
        console.log("no image paths");
        return;
      }
      if (image_paths.length < _idx) {
        this.dialog_text = "Image Not Found";
        console.log("exhausted all image paths");
        return;
      }

      let paths = [];

      for (let index = 0; index < image_paths.length; index++) {
        const image_path = image_paths[index];

        const query = gql`
          query ImageDownload($image_name: String!) {
            action_image_download(image_name: $image_name) {
              full_url
              reason
              result
              sas_url
            }
          }
        `;

        let image_name = image_path + ".jpg";
        console.log("trying image: " + image_name);

        try {
          const { data } = await this.$apollo.query({
            query: query,
            variables: {
              image_name,
            },
          });

          let url = data.action_image_download.full_url;
          let is_available = await this.is_image_available(url);
          if (is_available) {
            // image_path is id, we need to find the media data related to this id
            let media_data = full_media_data.find((m) => m.id == image_path);
            let api_response = "";
            if (media_data) {
              console.log("media_data is " + JSON.stringify(media_data));
              if (!media_data.api_response_data) {
                api_response = "";
              } else if (media_data.api_response_data.result) {
                api_response =
                  "Passed! Score: " + media_data.api_response_data.score;
              } else {
                api_response =
                  "Failed: " + JSON.stringify(media_data.api_response_data);
              }
            }
            paths.push({ url: url, api_response: api_response });
          }
        } catch (e) {
          console.log(e);
          console.log("error getting image, trying next one");
        }
      }

      this.sas_token_paths = paths;
      if (paths.length == 0) {
        this.dialog_text = "Image Not Found";
      }
    },
    async export_to_pdf() {
      // send data + header to server
      // server will generate pdf
      // server will return blob
      // client will save blob as pdf via a tag + click
      this.pdf_loading = true;

      let url =
        "https://com-sdcclinical-eyecup-prod.azurewebsites.net/api/pdf_data_table";

      // for local testing
      try {
        let uri = process.env.VUE_APP_URI.slice(20, 23);
        if (uri == "dev") {
          url =
            "https://com-sdcclinical-eyecup-dev.azurewebsites.net/api/pdf_data_table";
        }
      } catch (error) {
        console.log("error getting uri");
        console.log(error);
      }

      // url = "http://localhost:7071/api/pdf_data_table";

      let data = {
        input: {
          headers: this.headers,
          data: this.filtered_data.map((d) => {
            // convert null to ""
            Object.keys(d).forEach((key) => {
              if (d[key] == null) {
                d[key] = "";
              }
            });

            return {
              ...d,
              submitted_at: this.date_fmt(d.submitted_at),
              created_at: this.date_fmt(d.created_at),
              updated_at: this.date_fmt(d.updated_at),
            };
          }),
        },
      };

      let r = null;

      try {
        r = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      } catch (e) {
        console.log(e);
        this.pdf_loading = false;
        alert("Error generating PDF");
        return;
      }

      // if status code is not 200
      if (r.status != 200) {
        this.pdf_loading = false;
        console.log("status code is " + r.status);
        alert("Error generating PDF");
        return;
      }

      if (r === null) {
        this.pdf_loading = false;
        alert("Error generating PDF");
        console.log("result from server is null, or the fetch failed");
        return;
      }

      let blob = await r.blob();

      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "SDC Capture Report.pdf";
      link.click();

      // clean up link
      link.remove();
      this.pdf_loading = false;
    },
    date_fmt(dt) {
      if (dt == null) return "";
      if (dt == "") return "";
      let date = new Date(dt);
      let newDate = new Intl.DateTimeFormat("en-US", {
        timeZone: "America/New_York",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZoneName: "short",
      }).format(date);
      return newDate;
    },
    async update_site_list() {
      this.selected_site = null;
      this.assessment_id = null;
      this.selected_subject = "";
      this.selected_visit = null;
      this.data = [];
      if (this.$store.state.user.admin) {
        const { data } = await this.$apollo.query({
          query: SITE_LIST_ADMIN,
          client: this.uat_prod,
          variables: {
            study_id: this.study_id,
          },
        });
        this.site_list = data.dia_app_site.map((s) => ({
          text: s.name,
          value: s.id,
        }));
      } else {
        const { data } = await this.$apollo.query({
          query: SITE_LIST,
          client: this.uat_prod,
          variables: {
            study_id: this.study_id,
            user_id: this.$store.state.user.id,
          },
        });
        this.site_list = data.dia_app_site.map((s) => ({
          text: s.name,
          value: s.id,
        }));
      }

      if (this.site_list.length == 0) {
        alert("No sites found for this study");
      }
    },
    async load() {
      // This stops a race condition where the jwt is not loaded yet
      await this.$store.state.jwt_loading;
      this.study_list = [];
      this.study_id = null;
      this.selected_site = null;
      this.assessment_id = null;
      var token = null;

      //TODO - handle prod token globally instead of this
      token = localStorage.getItem("token");

      if (token) {
        let decoded = this.parseJwt(token);
        this.$store.state.user.id = +decoded.sub;
      }
      this.study_list = [];
      this.assessment_list = [];
      this.data = [];

      let data = null;

      if (this.$store.state.user.admin) {
        let r = await this.$apollo.query({
          query: STUDY_LIST_ADMIN,
        });
        data = r.data;
      } else {
        let r = await this.$apollo.query({
          query: STUDY_LIST,
          variables: {
            user_id: this.$store.state.user.id,
          },
        });
        data = r.data;
      }

      if (data.dia_app_user_study == null) {
        alert("No studies found for this user");
        return;
      }
      this.study_list = data.dia_app_user_study.map((s) => ({
        text: `${s.study.id}: ${s.study.name}`,
        value: s.study.id,
      }));
    },
    async update_assessment_list() {
      let data = null;

      if (this.$store.state.user.admin) {
        let r = await this.$apollo.query({
          query: ASSESSMENT_LIST_ADMIN,
          variables: {
            study_id: this.study_id,
          },
        });
        data = r.data;
      } else {
        let r = await this.$apollo.query({
          query: ASSESSMENT_LIST,
          variables: {
            study_id: this.study_id,
            user_id: this.$store.state.user.id,
          },
        });
        data = r.data;
      }

      this.assessment_list = data.dia_app_interval_assessment.map((ia) => ({
        text: ia.assessment.name,
        value: ia.assessment.id,
      }));
      if (this.assessment_list.length == 0) {
        alert("No assessments found for this study");
      }
    },

    async main_run() {
      this.ready = true;
      this.loading = true;
      this.data = null;

      let data = null;

      if (this.$store.state.user.admin) {
        let r = await this.$apollo.query({
          query: MAIN_QUERY_ADMIN,
          variables: {
            assessment_id: this.assessment_id, // 14
            study_id: this.study_id, // 24
            site_id: this.selected_site,
          },
        });
        data = r.data;
      } else {
        let r = await this.$apollo.query({
          query: MAIN_QUERY,
          variables: {
            assessment_id: this.assessment_id, // 14
            study_id: this.study_id, // 24
            user_id: this.$store.state.user.id,
            site_id: this.selected_site,
          },
        });
        data = r.data;
      }

      // we need to merge subject_full_id
      // first attempt to grab it from subject.subject_study_sites[0].subject_full_id
      // using a map

      // Step1: remove apollo reference, so the data can be mutated
      data = JSON.parse(JSON.stringify(data));
      data = {
        dia_app_record: data.dia_app_record.map((d) => {
          let subject_id = d.subject.subject_full_id;
          let subject_study_site = null;
          try {
            subject_study_site = d?.subject_study_site?.subject_full_id;
          } catch (error) {}

          d.subject.subject_full_id = subject_study_site || subject_id;
          return d;
        }),
      };

      this.result = data.dia_app_record;
      let found_headers = [];
      this.headers = [...this.base_headers];
      let d = this.result.map((r) => {
        let response_data = {};
        r.responses.forEach((response) => {
          if (!found_headers.includes(response.question.variable_name)) {
            found_headers.push(response.question.variable_name);
            this.headers.splice(9 + found_headers.length, 0, {
              text: response.question.label,
              value: response.question.variable_name,
            });
          }

          if (response.side === null) {
            if (response_data[response.question.variable_name]) {
              response_data[response.question.variable_name] +=
                " | " + response.value;
            } else {
              response_data[response.question.variable_name] = response.value;
            }
          } else {
            if (response_data[response.question.variable_name]) {
              response_data[response.question.variable_name] +=
                " | " + response.side + ": " + response.value;
            } else {
              response_data[response.question.variable_name] =
                response.side + ": " + response.value;
            }
          }
          if (
            response.value.endsWith(".jpg") ||
            response.value.endsWith(".mp4")
          ) {
            let media = response.media.map((m) => m.id);
            if (media.length == 0) {
              response_data[response.question.variable_name] = "MISSING";
            } else {
              response_data[response.question.variable_name] =
                "MEDIA: " + media.join(", ");
                response_data[response.question.variable_name + "_media_data"] = response.media.map((m) => {
                return {
                  id: m.id,
                  api_response_data: m.api_response_data,
                };
              });
            }
          }
        });

        let d = {
          id: r.id,
          study_name: r.study.name,
          study_identifier: r.study.study_identifier, // NOT WORKING?
          assessment_name: r.interval_assessment.assessment.name,
          assessment_key: r.interval_assessment.assessment.assessment_key, // NOT WORKING?
          interval_name: r.interval_assessment.interval.name,
          site_name: r.site.name,
          site_identifier: r.site.identifier, // NOT WORKING?
          record_status: r.status, // where?
          archived: r.archived,
          out_of_assessment_window_completion:
            r.out_of_assessment_window_completion,
          out_of_interval_window_completion:
            r.out_of_interval_window_completion,
          created_at: r.created_at,
          updated_at: r.updated_at,
          submitted_at: r.submitted_at,
          subject_full_id: r.subject.subject_full_id,
        };
        return { ...d, ...response_data };
      });

      this.data = d;

      this.loading = false;
    },
  },
  computed: {
    sites() {
      if (this.data == null) return [];
      return ["All"].concat(this.data.map((r) => r.site_name));
    },
    subjects() {
      if (this.data == null) return [];
      return ["All"].concat(this.data.map((r) => r.subject_full_id));
    },
    visits() {
      if (this.data == null) return [];
      return ["All"].concat(this.data.map((r) => r.interval_name));
    },
    filtered_data() {
      let d = this.data;
      // With new loading code,
      // this will happen while the data is loading
      if (d == null) return [];
      // if (this.selected_site && this.selected_site != "All") {
      //   d = d.filter((r) => r.site_name == this.selected_site);
      // }
      if (this.selected_subject && this.selected_subject != "All") {
        d = d.filter((r) => r.subject_full_id == this.selected_subject);
      }
      if (this.selected_visit && this.selected_visit != "All") {
        d = d.filter((r) => r.interval_name == this.selected_visit);
      }
      return d;
    },
    jsonData() {
      // [
      // { name: 'Joe', surname: 'Roe' },
      // { name: 'John', surname: 'Doe' }
      // ]

      return this.data.map((d) => {
        let f = {};
        this.headers.forEach((header) => {
          f[header.value] = d[header.value] || "";
        });
        return f;
      });
    },
  },
  async mounted() {
    await this.load();
  },
};
</script>
