<template>
  <v-container>
    <v-row class="my-2">
      <v-col align-self="end">
        <h1 class="display-6 font-weight-regular">Subjects</h1>
        <v-breadcrumbs :items="breadcrumbs" class="pl-0 pt-2 pb-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Subjects"
            v-model="toggle_archive"
          ></v-switch>
          <v-btn
            :disabled="oldStudyLock || study?.locked"
            color="primary"
            @click="newSubject"
          >
            New Subject
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-data-table
        :headers="headers"
        :items="filtered_data"
        :items-per-page="-1"
        :loading="loading"
        class="elevation-1"
        style="width: 100%"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="subject_study_site_id"
      >
        <template v-slot:item.updated_at="{ item }">
          {{ new Date(item.updated_at).toLocaleString() }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ new Date(item.created_at).toLocaleString() }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            :disabled="oldStudyLock || study?.locked"
            class="mx-2"
            icon
            small
            @click="editSubject(item)"
          >
            <v-icon dark> mdi-pencil-outline </v-icon>
          </v-btn>
          <v-btn
            :disabled="oldStudyLock || study?.locked"
            class="mx-2"
            icon
            small
            @click="archiveSubject(item)"
          >
            <v-icon dark v-if="!toggle_archive"> mdi-delete-outline </v-icon>
            <v-icon dark v-else> mdi-delete-restore </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
    <subject-form
      v-if="showSubjectForm"
      v-model="showSubjectForm"
      v-bind:subject="formSubject"
      v-bind:study_id="studyId"
    ></subject-form>
    <v-dialog v-model="showArchiveUserDialog" persistent max-width="400">
      <archive-form
        v-if="showArchiveUserDialog"
        :itemId="archive_id"
        :description="archive_description"
        :archive="toggle_archive"
        tableName="dia_app_subject_study_site"
        prettyName="Subject"
        @close="showArchiveUserDialog = false"
      ></archive-form>
    </v-dialog>
  </v-container>
</template>

<script>
// used for breadcrumb
import hasuraService from "@/services/hasura.service.js";

import SubjectForm from "@/components/subjects/SubjectForm.vue";
import ArchiveForm from "@/components/Archive.vue";
import gql from "graphql-tag";

const base_dia_app_subject_study_site = `
  dia_app_subject_study_site(where: {study_id: {_eq: $studyId}}) {
    id
    archived
    created_at
    site {
      id
      name
      user_study_sites {
        user_id
      }
    }
    subject_full_id
    subject {
      id
      created_by
      email
      updated_at
      user {
        name
      }
    }
  }
`;

const base_dia_app_subject = `
  dia_app_subject(where: {study_id: {_eq: $studyId}}) {
    id
    site {
      id
      name
      user_study_sites {
        user_id
      }
    }
    subject_full_id
    id
    archived
    created_by
    created_at
    updated_at
    user {
      name
    }
  }
`;

const SUBSCRIPTION_SUBJECTS_STUDY_SITE = gql`
  subscription SUBJECTS($studyId: Int!) {
    ${base_dia_app_subject_study_site}
  }
`;

const GET_SUBJECTS_STUDY_SITE = gql`
  query SUBJECTS($studyId: Int!) {
    ${base_dia_app_subject_study_site}
  }
`;

const SUBSCRIPTION_SUBJECTS = gql`
  subscription SUBJECTS($studyId: Int!, $userId: Int ) {
    ${base_dia_app_subject}
  }
`;

const GET_SUBJECTS = gql`
  query SUBJECTS($studyId: Int!, $userId: Int) {
    ${base_dia_app_subject}
  }
`;

export default {
  name: "SubjectsView",
  mixins: [hasuraService],

  components: {
    "subject-form": SubjectForm,
    "archive-form": ArchiveForm,
  },

  data() {
    return {
      loading: true,
      // This is a weird one; if
      oldStudyLock: false,
      sortBy: "subject_id",
      sortDesc: false,
      breadcrumbs: [
        {
          text: "Studies",
          disabled: false,
          to: { name: "Studies" },
          exact: true,
        },
        {
          text: "Study name",
          disabled: true,
        },
      ],

      toggle_archive: false,
      archive_id: null,
      archive_description: "",
      showArchiveUserDialog: false,
      study: null,

      headers: [
        { text: "Global ID", value: "subject_id" }, // subject.id
        { text: "Subject ID", value: "subject_study_site_id" }, // subject_study_site.id
        { text: "Subject Full ID", value: "subject_full_id" },
        { text: "Site", value: "site_name" },
        { text: "Created By", value: "created_by_name" },
        { text: "Created At", value: "created_at" },
        { text: "Actions", value: "actions", width: "120px" },
      ],
      dia_app_subject_study_site: [],
      dia_app_subject: [],
      showSubjectForm: false,
      formSubject: null,
    };
  },
  apollo: {
    dia_app_subject_study_site: {
      query: GET_SUBJECTS_STUDY_SITE,
      variables() {
        return {
          studyId: this.$route.params.id,
        };
      },
      subscribeToMore: {
        document: SUBSCRIPTION_SUBJECTS_STUDY_SITE,
        variables() {
          return {
            studyId: this.$route.params.id,
          };
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          return subscriptionData.data;
        },
      },
    },

    dia_app_subject: {
      query: GET_SUBJECTS,
      variables() {
        return {
          studyId: this.$route.params.id,
        };
      },
      subscribeToMore: {
        document: SUBSCRIPTION_SUBJECTS,
        variables() {
          return {
            studyId: this.$route.params.id,
          };
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          return subscriptionData.data;
        },
      },
    },
  },
  computed: {
    filtered_data() {
      // convert site.name to site_name
      if (this.dia_app_subject_study_site.length !== 0) {
        let d = this.dia_app_subject_study_site.map((item) => {
          // we need to do manual checking of site.user_study_sites.user_id since theres no clean way to do it in the query
          if (
            this.$store.state.user.admin === false &&
            item.site.user_study_sites.filter(
              (user) => user.user_id === this.$store.state.user.id
            ).length === 0
          ) {
            return null;
          }
          let _item = item.subject;
          return {
            ..._item,
            archived: item.archived || false, // archived SHOULD come from subject_study_site
            created_at: item.created_at, // kinda like archived, this needs to come from subject_study_site
            subject_study_site_id: item.id,
            subject_id: _item.id,
            site_name: item.site.name,
            site_id: item.site.id,
            created_by_name: _item.created_by + ": " + _item.user.name,
            subject_full_id: item.subject_full_id,
          };
        });

        // remove null items
        d = d.filter((item) => item !== null);

        d = d.filter((item) => {
          return item.archived === this.toggle_archive;
        });
        // in case of race condition
        this.oldStudyLock = false;
        return d;
      }

      let d2 = this.dia_app_subject.map((item) => {
        // we need to do manual checking of site.user_study_sites.user_id since theres no clean way to do it in the query
        if (
          this.$store.state.user.admin === false &&
          item.site.user_study_sites.filter(
            (user) => user.user_id === this.$store.state.user.id
          ).length === 0
        ) {
          return null;
        }
        return {
          ...item,
          subject_study_site_id: item.id,
          subject_id: item.id,
          site_name: item.site.name,
          site_id: item.site.id,
          created_by_name: item.created_by + ": " + item.user.name,
          subject_full_id: item.subject_full_id,
        };
      });

      d2 = d2.filter((item) => item !== null);

      d2 = d2.filter((item) => {
        return item.archived === this.toggle_archive;
      });

      // old study, we can't edit subjects.
      this.oldStudyLock = true;
      return d2;
    },
  },
  methods: {
    async newSubject() {
      this.formSubject = null;
      this.showSubjectForm = true;
    },
    async editSubject(subject) {
      console.log("editSubject", subject);
      this.formSubject = subject;
      this.showSubjectForm = true;
    },
    async archiveSubject(subject) {
      this.archive_id = subject.subject_study_site_id;
      this.archive_description = subject.subject_full_id;
      this.showArchiveUserDialog = true;
    },

    async setBreadcrumbs() {
      const study = this.$store.getters.study(this.$route.params.id);

      if (study === null) {
        this.study = await this.fetchStudyData(this.$route.params.id);
      } else {
        this.study = study;
      }

      if (this.study && this.study.name) {
        this.studyId = this.study.id;

        this.breadcrumbs = [
          {
            text: "Studies",
            disabled: false,
            to: { name: "Studies" },
            exact: true,
          },
          {
            text: this.study.name,
            disabled: true,
          },
        ];
      }
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    this.loading = false;
  },
};
</script>

<style>
.user-filter-form {
  width: 100%;
}
</style>
