<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >{{ formTitle }}
            <span v-if="is_edit">| ID: {{ subject_id }}</span>
          </span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Subject Full ID"
                  required
                  v-model="subject_full_id"
                  name="subject_full_id"
                  @keyup="removeErrorMessages"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Email"
                  type="email"
                  v-model="email"
                  name="email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <!-- dropdown -->
                <v-select
                  v-model="site_id"
                  :items="siteSelectOptions"
                  label="Site"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Created By"
                  disabled
                  required
                  :items="created_by_options"
                  v-model="created_by"
                ></v-select>
              </v-col>
              <v-col cols="6" v-if="is_edit">
                <v-select
                  label="Change Reason"
                  required
                  :items="$store.state.change_reason_options"
                  v-model="change_reason"
                  data-name="change_reason"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="updateOrCreateSubject"
            :loading="isLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- archive old - are you sure -->
    <v-dialog v-model="archive_old_confirm" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Archive Old Subject</span>
        </v-card-title>
        <v-card-text>
          By creating a new subject with the same email as a previous subject,
          the old subject will be archived. Are you sure you want to archive the
          old subject?
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click.stop="
              archive_old_confirm =
                false &&
                (archive_old_subjects_promise = null) &&
                (isLoading = false)
            "
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="archiveOldSubject"
            :loading="isLoadingArchive"
          >
            Archive Old Subject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["value", "study_id", "subject"],
  mixins: [hasuraService],
  data: () => ({
    isLoading: false,
    formTitle: "New Subject",

    old_subject: null,
    is_edit: false,

    siteSelectOptions: [],
    created_by_options: [],
    old_to_archive_subject_ids: null, // list || null
    archive_old_subjects_promise: null,
    archive_old_confirm: false,
    isLoadingArchive: false,

    change_reason: "",

    subject_id: null,
    subject_full_id: null,
    email: null,
    created_by: null,
    site_id: null,
    site: null,
    study: null,
    environment: "uat",
    subject_study_site_id: null,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    subject: {
      immediate: true,
      handler(newVal) {
        if (!(newVal === null || newVal === undefined)) {
          console.log("newVal", newVal);
          this.formTitle = "Edit Subject";
          this.subject_id = newVal.id;
          this.subject_study_site_id = newVal.subject_study_site_id;
          console.log("subject_study_site_id", this.subject_study_site_id);
          this.subject_full_id = newVal.subject_full_id;
          this.email = newVal.email;
          this.site_id = newVal.site_id;
          this.created_by = newVal.created_by;
          // TODO: replace with newVal.created_by_name
          this.created_by_options = [
            {
              text: newVal.created_by + ": " + newVal.user.name,
              value: newVal.created_by,
            },
          ];
          this.is_edit = true;

          this.general_application_audit_log_data(
            this.subject_id,
            "dia_app_subject"
          ).then((r) => {
            this.old_subject = r;
          });
        } else {
          console.log(this.$store.state.user);
          this.created_by = this.$store.state.user.id;
          // TODO: replace with newVal.created_by_name
          this.created_by_options = [
            {
              text: this.created_by + ": " + this.$store.state.user.name,
              value: this.$store.state.user.id,
            },
          ];
        }
      },
    },
  },
  methods: {
    async archiveOldSubject() {
      this.isLoadingArchive = true;
      await this.archiveSubjectStudySites(
        this.old_to_archive_subject_ids,
        this.$store.state.user,
        this.study
      );
      this.archive_old_confirm = false;
      this.archive_old_subjects_promise.resolve();
      this.isLoadingArchive = false;
    },
    removeErrorMessages() {
      console.log("removeErrorMessages");
      this.emailErrorMessage = "";
    },
    async fetchSites() {
      let data = await this.subjectFormSiteList(
        this.study_id,
        this.$store.state.user.id,
        false
      );
      this.study = data.study;

      this.siteSelectOptions = data.sites.map((site_data) => ({
        text: site_data.name,
        value: site_data.id,
      }));

      // if the length of siteSElectOptions is 1, set the site_id to the only site_id
      if (this.siteSelectOptions.length === 1) {
        this.site_id = this.siteSelectOptions[0].value;
      }
    },
    async updateOrCreateSubject() {
      this.isLoading = true;

      // validate email using regex
      if (!this.email) {
        this.$store.commit("setSnack", {
          text: "Please enter an email",
          color: "error",
        });
        this.isLoading = false;
        return;
      }
      const email_regex = new RegExp(
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
      );
      if (!email_regex.test(this.email)) {
        this.$store.commit("setSnack", {
          text: "Please enter a valid email",
          color: "error",
        });
        this.isLoading = false;
        return;
      }

      if (this.study.subject_id_regex_validation) {
        const regex = new RegExp(this.study.subject_id_regex_validation);
        if (!regex.test(this.subject_full_id)) {
          this.$store.commit("setSnack", {
            text:
              "Please use the following format: " +
              this.study.subject_id_regex_help_text,
            color: "error",
          });
          this.isLoading = false;
          return;
        }
      }

      if (this.is_edit) {
        console.log("is_edit");

        // if they are changing the email, check if it is unique
        if (this.old_subject.email !== this.email) {
          let is_unique = await this.checkSubjectEmailUnique(this.email);
          if (!is_unique) {
            this.$store.commit("setSnack", {
              text: "Editing subject email to an email that already exists is in SDC Capture requires administrative privileges to execute. Please contact support.",
              color: "error",
            });
            this.isLoading = false;
            return;
          }
        }

        // check if change_reason is empty
        if (this.change_reason === "") {
          this.$store.commit("setSnack", {
            text: "Please select a change reason",
            color: "error",
          });
          this.isLoading = false;
          return;
        }

        await this.updateSubjectF();
      } else {
        console.log("is_create");

        // check if email+site_id is unique
        // if not, warn, and if they accept, archive the old subject and create a new one

        this.old_to_archive_subject_ids =
          await this.getSubjectsByEmailAndSiteAndSite(
            this.email,
            this.study_id,
            this.site_id
          );
        if (this.old_to_archive_subject_ids.length > 0) {
          this.archive_old_confirm = true;
          // this will let us wait for the dialog to finish before continuing
          let p = new Promise((resolve) => {
            this.archive_old_subjects_promise = { resolve };
          });
          console.log("this.archive_old_subjects_promise", p);
          await p;
        }

        this.subject_id = await this.createSubjectF();
        // if this.subject_id is not a number, then the subject was not created
        // and we should return showing the error message
        if (isNaN(this.subject_id)) {
          this.$store.commit("setSnack", {
            text: this.subject_id || "Failed to create subject",
            color: "error",
          });
          this.subject_id = null;
          this.isLoading = false;
          return;
        }
      }

      // user, table, id, old_data, study
      this.general_application_audit_log(
        this.$store.state.user,
        "dia_app_subject",
        this.subject_id,
        this.old_subject,
        this.study,
        this.change_reason
      );
      this.isLoading = false;
    },
    async updateSubjectF() {
      let subject = {
        id: this.subject_id,
        subject_full_id: this.subject_full_id,
        site_id: this.site_id,
        email: this.email,
        created_by: this.created_by,
        current_date: new Date().toISOString(),
        subject_study_site_id: this.subject_study_site_id,
      };

      let r = await this.updateSubject(subject);

      console.log(r);
      if (r) {
        this.show = false;
      } else {
        this.$store.commit("setSnack", {
          text: "Failed to update subject",
          color: "error",
        });
      }
    },
    async createSubjectF() {
      // random 20 alphanumeric characters for the password
      let password = Array.from(
        { length: 20 },
        () =>
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"[
            Math.floor(Math.random() * 62)
          ]
      ).join("");
      // random 4 digit pin
      // let pin = parseInt(Math.random() * 10000);
      // IF pin is 00000000000000000000000000000000, then on the api, we will set it to null
      let pin = "00000000000000000000000000000000";

      let subject = {
        subject_full_id: this.subject_full_id + "",
        study_id: this.study_id + "",
        site_id: this.site_id + "",
        created_by: this.created_by + "",
        login: this.email + "",
        email: this.email + "",
        password: password + "",
        pin: pin + "",
        current_date: new Date().toISOString(),
      };

      let r = await this.createSubject(subject);
      this.show = false;
      return r;
    },
  },
  async mounted() {
    this.environment = localStorage.getItem("environment") || "uat";
    await this.$store.state.jwt_loading;
    this.fetchSites();
  },
};
</script>
